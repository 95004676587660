import {ApplicationInsights} from '@microsoft/applicationinsights-web'

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig()
  const searchInsights = new ApplicationInsights({config: {
    connectionString: runtimeConfig.public.SEARCH_INSIGHTS_CONNECTION_STRING,
    disableAjaxTracking: true,
    disableExceptionTracking: true,
    disableFetchTracking: true,
    disableTelemetry: true
  }})
  searchInsights.loadAppInsights()
  nuxtApp.provide('searchInsights', searchInsights)
})
