import {useUserStore} from '@/store/user'
import jwt_decode from 'jwt-decode'
import {getRegionForSalesArea} from '~/assets/js/utils/region_service'

export default defineNuxtPlugin({
  name: 'socialintents',
  setup(nuxtApp) {
    const scriptPath = useRuntimeConfig()?.public?.SOCIALINTENTS_SCRIPT

    // global callback to tell us the script is ready
    window.onSIApiReady = () => {
      nuxtApp.vueApp.__siapi__ready = true
      nuxtApp.runWithContext(async () => {
        const us = useUserStore()
        if (us.name && us.email) {
          // eslint-disable-next-line no-undef
          SI_API?.setChatInfo(us.name, us.email, '', '', '')
        }
        const decodedToken = jwt_decode(us.accessToken)
        // eslint-disable-next-line eqeqeq
        if (decodedToken != null) {
          let params = []
          params.push({name: 'CompanyNo', value: decodedToken.companyNo})
          params.push({name: 'SalesArea', value: decodedToken.salesAreaName})
          params.push({name: 'Region', value: getRegionForSalesArea(decodedToken.salesAreaName.replace('_', '-'))})
          // eslint-disable-next-line no-undef
          SI_API?.addParams(params)

        }
      })
    }

    // load script when user is logged in and has the config set up
    nuxtApp.provide('loadSocialIntents', (id) => {
      // eslint-disable-next-line eqeqeq
      if (scriptPath != null && document?.head != null && id != null) {
        const script = document.createElement('script')
        script.src = scriptPath + id
        script.async = true
        document.head.appendChild(script)
      }
    })

    // dynamically set the chat info
    nuxtApp.provide('setNameEmailSocialIntents', (name, email) => {
      // eslint-disable-next-line no-undef
      SI_API?.setChatInfo(name, email, '', '', '')
    })

    nuxtApp.provide('hideTabSocialIntents', () => {
      // eslint-disable-next-line no-undef
      SI_API?.hideTab()
    })

    nuxtApp.provide('showTabSocialIntents', () => {
      // eslint-disable-next-line no-undef
      SI_API?.showTab()
    })
  }
})
