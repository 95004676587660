import {TOKEN_TYPES} from './Token.js'
import tokenize from './tokenizer.js'

const parse = function (expr) {
  let tokens = tokenize(expr)
  return parseLogical(tokens)
}

const forceTokenType = function (tokens, tokenType) {
  const closingToken = tokens.shift()
  if (closingToken.type !== tokenType) {
    throw new Error(`Expected token type: ${tokenType} got ${closingToken.type}`)
  }
}

const parseLeftComparison = function (tokens) {
  let nextTokenType = tokens[0].type
  if (nextTokenType === TOKEN_TYPES.SYMBOL && tokens[0].value.toLowerCase() !== 'and' && tokens[0].value.toLowerCase() !== 'or') {
    return tokens.shift().value
  }

  throw new Error('Expected symbol token, got ' + nextTokenType)
}

const parseLogicalOperator = function (tokens) {
  let nextToken = tokens.shift()
  if ((nextToken.type === TOKEN_TYPES.OPERATOR && nextToken.value === '&') ||
     (nextToken.type === TOKEN_TYPES.SYMBOL && nextToken.value === 'and'))
  {
    return 'and'
  } else if ((nextToken.type === TOKEN_TYPES.OPERATOR && nextToken.value === '|') ||
             (nextToken.type === TOKEN_TYPES.SYMBOL && nextToken.value === 'or')) {
    return 'or'
  }

  throw new Error('expected operator, got: ' + nextToken)
}

const parseLogical = function (tokens) {
  let nextTokenType = tokens[0].type

  let left = {}
  if (nextTokenType === TOKEN_TYPES.EXPRESSION) {
    let expression = tokens.shift().value
    left = {expression}
  } else if (nextTokenType === TOKEN_TYPES.BRACKET_OPEN) {
    tokens.shift()
    left = parseLogical(tokens)
    forceTokenType(tokens, TOKEN_TYPES.BRACKET_CLOSE)
  } else {
    left = parseBinaryComparison(tokens)
  }

  if (tokens.length > 0) {
    nextTokenType = tokens[0].type
    if (nextTokenType === TOKEN_TYPES.OPERATOR || (nextTokenType === TOKEN_TYPES.SYMBOL && (tokens[0].value.toLowerCase() === 'and' || tokens[0].value.toLowerCase() === 'or'))) {
      let operator = parseLogicalOperator(tokens)
      return {
        [operator]: {
          left,
          right: parseLogical(tokens)
        }
      }
    }
  }
  return left
}

const parseRight = function (tokens) {
  let nextToken = tokens.shift()
  if (nextToken.type === TOKEN_TYPES.NUMBER || nextToken.type === TOKEN_TYPES.STRING || nextToken.type === TOKEN_TYPES.EXPRESSION) {
    return nextToken.value
  }

  throw new Error('Expected string or number, got: ' + nextToken)
}

const parseComparisonOperator = function (tokens) {
  let token = tokens.shift()
  if (token.type !== TOKEN_TYPES.OPERATOR) {
    throw new Error('Expected operator!')
  }

  if (token.value === '!=') {
    return 'ne'
  }

  if (token.value === '>=') {
    return 'ge'
  }

  if (token.value === '>') {
    return 'gt'
  }

  if (token.value === '<=') {
    return 'le'
  }

  if (token.value === '<') {
    return 'lt'
  }

  if (token.value === '=') {
    return 'eq'
  }

  if (token.value === '*=') {
    return 'contains'
  }

  if (token.value === '~=') {
    return 'contains_insensitive'
  }
}

const parseBinaryComparison = function (tokens) {
  let left = parseLeftComparison(tokens)
  let operator = parseComparisonOperator(tokens)
  let right = parseRight(tokens)
  if (typeof (operator) === 'undefined') {
    throw new Error('Undefined operator')
  }
  return {
    [operator]: {
      left,
      right
    }
  }
}

const convertQuotes = function(expr) {
  if (/^"(.*)"$/.test(expr)) {
    return '\'' + /^"(.*)"$/.exec(expr)[1] + '\''
  }

  return expr
}

const buildOdataFilter = function (filter, isFirst) {
  let keys = Object.keys(filter)

  const firstKey = keys[0]
  if (['eq', 'ne', 'gt', 'ge', 'lt', 'le'].indexOf(firstKey) >= 0) {
    return `${filter[firstKey].left} ${firstKey} ${convertQuotes(filter[firstKey].right)}`
  }

  if (['and', 'or'].indexOf(firstKey) >= 0) {
    return `${!isFirst ? '(' : ''}${buildOdataFilter(filter[firstKey].left)} ${firstKey} ${buildOdataFilter(filter[firstKey].right)}${!isFirst ? ')' : ''}`
  }

  if (['contains'].indexOf(firstKey) >= 0) {
    return `${firstKey}(${filter[firstKey].left}, ${convertQuotes(filter[firstKey].right)})`
  }

  if (['contains_insensitive'].indexOf(firstKey) >= 0) {
    return `contains(tolower(${filter[firstKey].left}), ${convertQuotes(filter[firstKey].right.toLowerCase())})`
  }

  if (firstKey === 'expression') {
    return filter[firstKey]
  }

  throw new Error('unexpected filter: ' + firstKey + '.')
}

const strapifyOperator = function (op) {
  switch (op) {
  case 'ne':
  case 'lt':
  case 'gt':
  case 'eq':
    return op
  case 'le':
    return 'lte'
  case 'ge':
    return 'gte'
  }

  return op
}

/**
 * builds filter query for strapi. DOES NOT SUPPORT "OR" OPERATOR!
 * @param {object} filter the filter object
 */
const buildStrapiFilter = function (filter) {
  let keys = Object.keys(filter)
  if (keys.length === 0) {
    return {}
  }
  const firstKey = keys[0]
  if (['eq', 'ne', 'gt', 'ge', 'lt', 'le'].indexOf(firstKey) >= 0) {
    return {
      [filter[firstKey].left + '_' + strapifyOperator(firstKey)]: filter[firstKey].right
    }
  }

  if (firstKey === 'or') {
    throw new Error('OR filter is currently not supported by strapi')
  }

  if (firstKey === 'and') {
    return {...buildStrapiFilter(filter[firstKey].left), ...buildStrapiFilter(filter[firstKey].right)}
  }
}

/**
 * Determine if a value is a Date
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a Date, otherwise false
 */
function isDate(val) {
  return toString.call(val) === '[object Date]'
}

/**
 * Determine if a value is an Object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Object, otherwise false
 */
function isObject(val) {
  return val !== null && typeof val === 'object'
}

/**
 * Determine if a value is an Array
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is an Array, otherwise false
 */
function isArray(val) {
  return toString.call(val) === '[object Array]'
}

function encode(val) {
  return encodeURIComponent(val).
    replace(/%3A/gi, ':').
    replace(/%24/g, '$').
    replace(/%2C/gi, ',').
    replace(/%20/g, '+').
    replace(/%5B/gi, '[').
    replace(/%5D/gi, ']')
}

const searlializeParams = function(params) {
  var parts = []

  for (let [key, val] of Object.entries(params)) {
    if (val === null || typeof val === 'undefined') {
      return
    }

    if (!isArray(val)) {
      val = [val]
    }

    for (let v of val) {
      if (isDate(v)) {
        v = v.toISOString()
      } else if (isObject(v)) {
        v = JSON.stringify(v)
      }
      parts.push(encode(key) + '=' + encode(v))
    }
  }

  return parts.join('&')
}

/**
 * creates a query for OData / Strapi CMS.
 * Use "linq-like" methods: where, top, skip, select, orderBy, thenBy, orderByDesc, thenByDesc
 */
const Query = function () {
  let filter = {}
  let top = null
  let skip = null
  let sort = []
  let select = null
  let count = false
  let search = null

  this.getFilter = () => ({...filter})
  this.getTop = () => top
  this.getSkip = () => skip
  this.getSort = () => sort
  this.getSelect = () => select
  this.getCount = () => count
  this.getSearch = () => search

  /**
   * use to filter the query
   * @param {string} whereQuery a query for filtering. The syntax is Field[<|>|=|!=|>=|<=]Value, you can concat multiple filter using and/or. Value has to be numeric or enclosed in quotes
   * @returns {Query} the updated Query object
   */
  this.where = function (whereQuery) {
    let whereFilter = parse(whereQuery)
    if (filter && Object.keys(filter).length > 0) {
      filter = {
        and: {
          left: {...filter},
          right: whereFilter
        }
      }
    } else {
      filter = whereFilter
    }
    return this
  }

  /**
   * Use to select top X entries
   * @param {number} val the number of results to be returned
   * @returns {Query} the updated Query object
   */
  this.top = function (val) {
    if (typeof (val) === 'number') {
      top = val
    }

    return this
  }

  /**
   * Use to skip entries
   * @param {number} val number of entries to skip
   * @returns {Query} the updated Query object
   */
  this.skip = function (val) {
    if (typeof (val) === 'number') {
      skip = val
    }

    return this
  }

  /**
   * Method to pass an initial "orderby" (ascending) to the query
   * @param {string} field the field to sort by
   * @returns {Query} the updated Query object
   */
  this.orderBy = function (field) {
    sort = [{[field]: 'asc'}]
    return this
  }

  /**
   * Method to pass an initial "orderby" (descending) to the query
   * @param {string} field the field to sort by
   * @returns {Query} the updated Query object
   */
  this.orderByDesc = function (field) {
    sort = [{[field]: 'desc'}]
    return this
  }

  /**
   * Method to pass an additional "orderby" (ascending) to the query
   * @param {string} field the field to sort by
   * @returns {Query} the updated Query object
   */
  this.thenBy = function (field) {
    sort.push({[field]: 'asc'})
    return this
  }

  /**
   * Method to pass an additional "orderby" (descending) to the query
   * @param {string} field the field to sort by
   * @returns {Query} the updated Query object
   */
  this.thenByDesc = function (field) {
    sort.push({[field]: 'desc'})
    return this
  }

  /**
   * Method indicate to include count in the results
   * @returns {Query} the updated Query object
   */
  this.includeCount = function () {
    count = true
    return this
  }

  /**
   * Limits the result to the given fields
   * @param {string|array} fields if string, a comma-delimited list of fields is expected, otherwise the array of strings (one string per field)
   * @returns {Query} the updated Query object
   */
  this.select = function (fields) {
    if (typeof (fields) === 'string') {
      select = fields.split(',')
    } else if (fields && typeof (fields) === 'object' && Object.prototype.hasOwnProperty.call(fields, 'length')) {
      select = fields
    }

    return this
  }

  /**
   * Adds a "search term" to the query (not supported by all APIs!)
   * @param {string} searchQuery the query to send with the request
   */
  this.search = function(searchQuery) {
    search = searchQuery

    return this
  }

  /**
   * @returns a string representation of the query
   */
  this.toString = function () {
    return JSON.stringify({
      top,
      skip,
      sort,
      filter,
      select,
      search
    })
  }

  /**
   * @returns OData-Params representing this query to be passed to axios "params"-option
   */
  this.toOdataParams = function (params) {
    let p = {}

    if (typeof (top) === 'number') {
      p.$top = top
    }

    if (typeof (skip) === 'number') {
      p.$skip = skip
    }

    if (filter && filter.constructor === Object && Object.keys(filter).length > 0) {
      p.$filter = buildOdataFilter(filter, true)
    }

    if (count) {
      p.$count = true
    }

    if (sort.length > 0) {
      p.$orderby = sort.reduce((o, c) => o.concat(Object.keys(c)[0] + ' ' + c[Object.keys(c)[0]]), []).join(',')
    }

    if (select && select.length > 0) {
      p.$select = select.join(',')
    }

    if (search) {
      p.$search = search
    }

    if (params) {
      p = {...p, ...params}
      return new URLSearchParams(searlializeParams(p))
    }

    return p
  }

  /**
   * @returns Post-Params representing this query to be passed to axios "params"-option
   */
  this.toPostParams = function (params) {
    let p = {}

    if (typeof (top) === 'number') {
      p.top = top
    }

    if (typeof (skip) === 'number') {
      p.skip = skip
    }

    if (filter && filter.constructor === Object && Object.keys(filter).length > 0) {
      p.filter = buildOdataFilter(filter, true)
    }

    if (count) {
      p.count = true
    }

    if (sort.length > 0) {
      p.orderby = sort.reduce((o, c) => o.concat(Object.keys(c)[0] + ' ' + c[Object.keys(c)[0]]), []).join(',')
    }

    if (select && select.length > 0) {
      p.select = select.join(',')
    }

    if (search) {
      p.search = search
    }

    if (params) {
      p = {...p, ...params}
    }

    return p
  }

  /**
   * @returns OData-Params representing this query to be passed to axios "params"-option when requesting from Strapi
   */
  this.toStrapiParams = function (params) {
    let p = {}
    if (typeof (top) === 'number') {
      p._limit = top
    }

    if (typeof (skip) === 'number') {
      p._start = skip
    }

    if (filter) {
      p = {...p, ...buildStrapiFilter(filter)}
    }

    if (count) {
      // not supported by strapi
    }

    if (sort.length > 0) {
      p._sort = sort.reduce((o, c) => o.concat(Object.keys(c)[0] + ':' + c[Object.keys(c)[0]]), []).join(',')
    }

    if (select && select.length > 0) {
      // not supported by strapi
    }

    if (params) {
      p = {...p, ...params}
      return new URLSearchParams(searlializeParams(p))
    }

    return p
  }

  return this
}

export default Query
