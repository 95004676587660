import jwt_decode from 'jwt-decode'
import {encodeQuery} from '~/assets/js/utils'
import {useCustomerService} from '@/composables/customer_service'
import {DefaultCustomerUuid, MAX_CUSTOMERS} from '~/assets/js/constants'
import * as Sentry from '@sentry/browser'
import {useUserStore} from '@/store/user'
import {useCartStore} from '@/store/cart'
import {usePlantStore} from '@/store/plants'
import {useCustomerSettingsStore} from '@/store/customer-settings'
import {useAuthStore} from '@/store/auth'
import {useAuthService} from '@/composables/auth_service'
import {abortNavigation} from '#app'
import {useGtm} from '@gtm-support/vue-gtm'
import {getRegionForSalesArea} from '../utils/region_service'

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

function randomString (length) {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export async function passwordReset(_opts = {}) {
  const runtimeConfig = useRuntimeConfig()
  const opts = {
    protocol: 'oauth2',
    response_type: runtimeConfig.public.auth.responseType,
    access_type: runtimeConfig.public.auth.accessType,
    client_id: runtimeConfig.public.auth.clientId,
    redirect_uri: runtimeConfig.public.BASE_URL + runtimeConfig.public.auth.redirect.login,
    scope: runtimeConfig.public.auth.scope,
    state: _opts.state || randomString(10),
    code_challenge_method: runtimeConfig.public.auth.codeChallengeMethod,
    ..._opts.params
  }
  if (runtimeConfig.public.auth.audience) {
    opts.audience = runtimeConfig.public.auth.audience
  }

  if (opts.response_type.includes('token')) {
    opts.nonce = _opts.nonce || randomString(10)
  }
  if (opts.code_challenge_method) {
    switch (opts.code_challenge_method) {
    case 'plain':
    case 'S256':
      {
        const codeVerifier = randomString(128)
        const encoder = new TextEncoder()
        let codeChallenge = encoder.encode(codeVerifier)
        if (opts.code_challenge_method === 'S256') {
          const arrayBuffer = await crypto.subtle.digest('SHA-256', codeChallenge)
          codeChallenge = btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
        }

        opts.code_challenge = window.encodeURIComponent(codeChallenge)
      }
      break
    }
  }
  if (runtimeConfig.public.auth.responseMode) {
    opts.response_mode = runtimeConfig.public.auth.responseMode
  }
  if (runtimeConfig.public.auth.acrValues) {
    opts.acr_values = runtimeConfig.public.auth.acrValues
  }
  opts.lang = useNuxtApp().$i18n.locale.value || 'en'

  const url = runtimeConfig.public.auth.endpoints.passwordReset + '?' + encodeQuery(opts)
  window.location.replace(url)

  abortNavigation()
}

export async function userImpersonation (userManager, _opts = {}) {
  const app = useNuxtApp()
  const state = {
    signInRedirect: null,
    locale: app.$i18n.locale.value,
    region: app.$globalization.getRegion()
  }

  await userManager.signinRedirect({state, extraQueryParams: {
    lang: app.$i18n.locale.value,
    region: app.$globalization.getRegion(),
    ..._opts.params
  }})
}

export async function fetchUser(userManager, gtmAction = 'login', impersonateToken = '') {
  let token = impersonateToken !== '' ? ('Bearer ' + impersonateToken) : (await getB2CToken(userManager))
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const {getAccessToken} = useAuthService()
  const app = useNuxtApp()
  const customerService = useCustomerService()
  // Token is required but not available
  if (!token) {
    return
  }
  else {
    try {
      let user = await userManager.getUser()
      if (!user) {
        return
      }

      const decodedBaseToken = jwt_decode(token)
      if (decodedBaseToken?.accountDoiVerified !== true) {
        authStore.setUserObject(null)
        userStore.accessToken = ''
        userManager.signoutRedirect({extraQueryParams: {
          id_token_hint: user.id_token,
          post_logout_redirect_uri: useRuntimeConfig().public.BASE_URL + app.vueApp.localePath('verification-required')
        }})

        return
      }

      if (impersonateToken !== '') {
        user.access_token = impersonateToken
        userManager.storeUser(user)
        authStore.setUserObject(user)
      }
      const customerUuid = userStore.newCustomer ? userStore.newCustomer : userStore.selectedCustomer?.customerUuid || 'default'
      const res = await getAccessToken(customerUuid, token)

      if (res) {
        const decodedToken = jwt_decode(res.data.accessToken)
        const userUuid = decodedToken?.oid
        if (userUuid) {
          userStore.setUserUuid(userUuid)
        }
        const userName = decodedToken?.name
        if (userName) {
          userStore.setUserName(userName)
        }
        userStore.setAccessToken(res.data.accessToken)
        const region = app.$globalization.getRegionFromURL()
        const customerRegion = getRegionForSalesArea(decodedToken.salesAreaName.replaceAll('_', '-'))
        app.$globalization.setRegion(customerRegion)
        if (region && customerRegion && region !== customerRegion) {
          const targetUrl = app.$globalization.getRegionPath(customerRegion)
          if (navigator.userAgent.includes('Firefox')) {
            setTimeout(() => {
              window.location.replace(targetUrl)
            }, 500)
          } else {
            window.location.replace(targetUrl)
          }
          return
        }
        userStore.fetchUserData()
        userStore.parseUserRights()
        userStore.parseExcludedBrands()
        userStore.parseIncludedBrands()
        let defaultcustomerUuid = decodedToken?.iss

        useGtm()?.trackEvent({
          event: 'login',
          category: 'login',
          action: gtmAction,
          companyNo: decodedToken?.companyNo,
          salesAreaName: decodedToken?.salesAreaName,
          userType: decodedToken?.userIsInternal === 'true' ? 'internal' : 'external'
        })

        if (defaultcustomerUuid !== DefaultCustomerUuid) {
          userStore.companiesLoading = true
          let defaultCustomer = null
          if (decodedToken.scope.split(' ').some(s => s === `https://sso.fst.com/${defaultcustomerUuid}/Customer.View`)) {
            defaultCustomer = await customerService.getCustomer(defaultcustomerUuid.split('/')[3], res.data.accessToken)
          } else {
            defaultCustomer = await customerService.getBasicCustomer(defaultcustomerUuid.split('/')[3], res.data.accessToken)
          }
          await userStore.setSelectedCustomer(defaultCustomer?.value.find(x => x))
          const userCustomerSettingsStore = useCustomerSettingsStore()
          await userCustomerSettingsStore.fetchCustomerSettings()
          customerService.getFilteredCustomerList(undefined, MAX_CUSTOMERS).then((companies) => {
            userStore.setAvailableCompanies(companies.count)
            userStore.setCompanies(companies.value)
            userStore.companiesLoading = false
          })
          usePlantStore().fetchAllPlants(false)
        }

        const cartStore = useCartStore()
        cartStore.fetchCartCount()
        cartStore.fetchCertificates()
        if (decodedToken.loginWizardDone === 'false') {
          return navigateTo(app.$globalization.localePath('/setup'))
        }
      } else {
        // eslint-disable-next-line no-console
        console.warn('Could not get accessToken')
      }
    }
    catch (e) {
      await userManager.removeUser()
      let tokenValue = 'No token logged for PRD'
      let tokenFull = 'No token logged for PRD'
      let customer = userStore.selectedCustomer
      if (process.env.SENTRY_ENVIRONMENT === 'DEV' || process.env.SENTRY_ENVIRONMENT === 'STG') {
        tokenValue = (await userManager.getUser())?.access_token
        tokenFull = JSON.stringify(userManager.getUser())
      }

      try {
        Sentry.captureMessage('Fetch User Exception: ' + (e && e.message) + ' token: ' + tokenValue + ' Customer: ' + JSON.stringify(customer) + ' FullTokenInfo: ' + JSON.stringify(tokenFull), {
          level: 'warning'
        })
      }
      catch (ex) {
        // do nothing
      }

      /* eslint-disable-next-line no-console */
      console.error(e)
    }
  }

  // Azure B2C does not allow userinfo
  /* if (!this.options.endpoints.userInfo) {
    let decodedToken = jwt_decode(token)
    this.$auth.setUser({
      name: decodedToken.name || decodedToken.unique_name,
      email: decodedToken.email
    })
  } else {
    return super.fetchUser()
  } */
}

export async function getB2CToken(userManager) {
  try {
    //default silent renew = true, so less logic here
    const user = await userManager.getUser()
    const tokenExpired = user?.expired

    if (tokenExpired) {
      userManager.removeUser()
      return null
    }
    return user?.token_type + ' ' + user?.access_token
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex)
  }
}
