import {TOKEN_TYPES, Token} from './Token.js'

const nextToken = function(expressionArray) {
  if (expressionArray.length === 0) {
    return new Token(TOKEN_TYPES.EOF, TOKEN_TYPES.EOF)
  }

  let currentChar = expressionArray.shift()
  while (currentChar === ' ' && expressionArray.length > 0) {
    currentChar = expressionArray.shift()
  }

  if (currentChar === ' ' && expressionArray.length === 0) {
    return new Token(TOKEN_TYPES.EOF, TOKEN_TYPES.EOF)
  }

  let nextChar = expressionArray.length > 0 ? expressionArray[0] : ''

  if (currentChar === '(' || currentChar === '[' || currentChar === '{') {
    return new Token(currentChar, TOKEN_TYPES.BRACKET_OPEN)
  }

  if (currentChar === ')' || currentChar === ']' || currentChar === '}') {
    return new Token(currentChar, TOKEN_TYPES.BRACKET_CLOSE)
  }

  if (currentChar === '>') {
    if (nextChar === '=') {
      expressionArray.shift()
      return new Token('>=', TOKEN_TYPES.OPERATOR)
    }

    return new Token('>', TOKEN_TYPES.OPERATOR)
  }

  if (currentChar === '<') {
    if (nextChar === '=') {
      expressionArray.shift()
      return new Token('<=', TOKEN_TYPES.OPERATOR)
    }

    return new Token('<', TOKEN_TYPES.OPERATOR)
  }

  if (currentChar === '!') {
    if (nextChar === '=') {
      expressionArray.shift()
    } else {
      throw new Error('"!" has to be followed by "="')
    }

    return new Token('!=', TOKEN_TYPES.OPERATOR)
  }

  if (currentChar === '*') {
    if (nextChar === '=') {
      expressionArray.shift()
    } else {
      throw new Error('"*" has to be followed by "="')
    }

    return new Token('*=', TOKEN_TYPES.OPERATOR)
  }

  if (currentChar === '~') {
    if (nextChar === '=') {
      expressionArray.shift()
    } else {
      throw new Error('"~" has to be followed by "="')
    }

    return new Token('~=', TOKEN_TYPES.OPERATOR)
  }

  // duplicated operators = & |
  if (/[=|&]/.test(currentChar)) {
    if (nextChar === currentChar) {
      expressionArray.shift()
    }

    return new Token(currentChar, TOKEN_TYPES.OPERATOR)
  }

  if (/[a-zA-Z_]/i.test(currentChar)) {
    var symbolToken = currentChar
    while (expressionArray.length > 0 && /[a-zA-Z_.]/i.test(expressionArray[0])) {
      symbolToken += expressionArray.shift()
    }
    return new Token(symbolToken, TOKEN_TYPES.SYMBOL)
  }

  if (/\d/.test(currentChar) || (/[+-.]/.test(currentChar) && /\d/.test(nextChar))) {
    let numericToken = currentChar
    while (/[0-9.]/.test(nextChar)) {
      numericToken += expressionArray.shift()
      nextChar = expressionArray.length > 0 ? expressionArray[0] : ''
    }
    return new Token(numericToken, TOKEN_TYPES.NUMBER)
  }

  if (/['"']/.test(currentChar)) {
    let closingQuote = currentChar
    let stringToken = currentChar
    do {
      if (expressionArray.length === 0) {
        throw new Error('Unexpected end of string')
      }
      currentChar = expressionArray.shift()
      stringToken += currentChar
    } while (currentChar !== closingQuote)
    return new Token(stringToken, TOKEN_TYPES.STRING)
  }

  if (/[`]/.test(currentChar)) {
    let closingBracket = '`'
    let expressionToken = ''
    currentChar = expressionArray.shift()
    while (currentChar !== closingBracket) {
      if (expressionArray.length === 0) {
        throw new Error('Unexpected end of expression')
      }
      expressionToken += currentChar
      currentChar = expressionArray.shift()
    }
    return new Token(expressionToken, TOKEN_TYPES.EXPRESSION)
  }

  throw new Error('Unexpected character: ' + currentChar)
}

export default function (expr) {
  let ret = []
  let expressionArray = [...expr]
  let token
  do {
    token = nextToken(expressionArray)
    ret.push(token)
  } while (token.type !== TOKEN_TYPES.EOF)
  ret.pop()

  return ret
}
