import baseAxios from '@/services/axios_base.js'

export const useAuthService = () => {

  const baseurl = useRuntimeConfig().public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const auth_axios = baseAxios.create({
    baseURL: useRuntimeConfig().public.AUTH_API || baseurl
  })

  const getAccessToken = async function(customerUuid, bearerToken) {
    let sanitizedCustomerUuid = customerUuid
    if (!sanitizedCustomerUuid) {
      sanitizedCustomerUuid = 'default'
    }
    return auth_axios.get(`/${sanitizedCustomerUuid}/auth/token`, {headers: {Authorization: bearerToken}})
  }

  const generateCompanyToken = async function(customerUuid, bearerToken) {
    let sanitizedCustomerUuid = customerUuid
    if (!sanitizedCustomerUuid) {
      sanitizedCustomerUuid = 'default'
    }
    return auth_axios.post(`/1.0.0/${sanitizedCustomerUuid}/auth/companyToken`, {}, {headers: {Authorization: 'Bearer ' + bearerToken}}).catch(() => {return {accessToken: null}})
  }

  const getTokenInformation = async function(customerUuid, bearerToken) {
    let sanitizedCustomerUuid = customerUuid
    if (!sanitizedCustomerUuid) {
      sanitizedCustomerUuid = 'default'
    }
    return auth_axios.get(`/1.0.0/${sanitizedCustomerUuid}/auth/companyToken`, {headers: {Authorization: 'Bearer ' + bearerToken}}).catch(() => {return {isActive: null, valideUntil: null, validFrom: null}})
  }

  const getCompanyTokens = async function(customerUuid, bearerToken) {
    if (customerUuid) {
      return auth_axios.get(`/1.0.0/${customerUuid}/auth/companyTokens`, {headers: {Authorization: 'Bearer ' + bearerToken}}).catch(() => {return []})
    } else {
      return []
    }
  }

  return {
    getAccessToken,
    generateCompanyToken,
    getTokenInformation,
    getCompanyTokens
  }
}
