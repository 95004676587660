import type {paths, components} from '@/types/hydras.d'

type Path = keyof paths;
type PathMethod<T extends Path> = keyof paths[T];
type RequestParams<
  P extends Path,
  M extends PathMethod<P>
> = paths[P][M] extends {
  parameters: any;
}
  ? paths[P][M]['parameters']
  : undefined;

type ResponseType<
  P extends Path,
  M extends PathMethod<P>
> = paths[P][M] extends {
  responses: { 200: { content: { [x: string]: any } } };
}
  ? paths[P][M]['responses'][200]['content']['application/json']
  : undefined;

type RequestType<
  P extends Path,
  M extends PathMethod<P>
> = paths[P][M] extends {
  requestBody: { content: { [x: string]: any } };
}
  ? paths[P][M]['requestBody']['content']['application/json']
  : undefined;

export const getReponseType = <P extends Path, M extends PathMethod<P>>(
  url: P,
  method: M,
  ...params: RequestParams<P, M> extends undefined ? [] : [RequestParams<P, M>]
): ResponseType<P, M> => {
  // ...
}

export const getRequestType = <P extends Path, M extends PathMethod<P>>(
  url: P,
  method: M,
  ...params: RequestParams<P, M> extends undefined ? [] : [RequestParams<P, M>]
): RequestType<P, M> => {
  // ...
}

export function typeGuard<T>(object: any): object is T {
  // const schema: Record<keyof T, string> = {
  //     id: 'string',
  //     collection: 'array'
  // };

  // const missingProperties = Object.keys(schema)
  //     .filter(key => object[key] === undefined)
  //     .map(key => key as keyof T)
  //     .map(key => new Error(`Document is missing ${key} ${schema[key]}`));

  // // throw the errors if you choose

  // return missingProperties.length === 0;
  return true
}

export function newEmptyPostBody(): components['schemas']['ApiPostProductRequest'] {
  return {
    unitsOfMeasure: {
      temperature: null,
      pressure: null,
      length: null
    },
    temperatureMax: null,
    temperatureMin: null,
    pressureMax: null,
    pressureConst: null,
    speedMax: null,
    cylinderType: null,
    dirtExposure: null,
    operatingDuration: null,
    isReplacementPart: false,
    wiper: {
      rodDiameter: null,
      outerDiameter: null,
      installationSpaceWidth: null,
      installationSpaceWidth1: null,
      diameter1: null,
      variant: null
    },
    rodSeal: {
      rodDiameter: null,
      outerDiameter: null,
      installationSpaceWidth: null
    },
    rodGuide: {
      rodDiameter: null,
      outerDiameter: null,
      installationSpaceWidth: null
    },
    pistonSeal: {
      innerDiameter: null,
      boreDiameter: null,
      installationSpaceWidth: null,
      diameter1: null,
      installationSpaceWidth1: null,
      variant: null
    },
    pistonGuide: {
      innerDiameter: null,
      boreDiameter: null,
      installationSpaceWidth: null
    },
    bufferSeal: {
      rodDiameter: null,
      outerDiameter: null,
      installationSpaceWidth: null
    }
  }
}
